.footer-container {
	font-size: 15px;
	padding: 20px 0 10px 0;
	margin-top: auto;
}

@media screen and (max-width: 767px) {
	.footer-container {
		font-size: 14px;
	}
}
