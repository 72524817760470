input[type=search].search-list-input {
	background-color: #ccc;
	padding: 10px 40px 10px 20px;
	border-radius: 10px;
	font-size: 18px;
}

.search-list-button {
	position: absolute;
	top: 15px;
	right: 10px;
	padding: 0;
}

.search-list-button i {
	font-size: 20px;
	color: #808080;
	margin-right: 0;
}
