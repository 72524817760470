.account-billing-container {
	/* width: 50%; */
	padding: 20px 10px;
}

.account-billing-container .current-plan {
	font-size: 20px;
	padding-bottom: 20px;
}

.account-billing-container .current-plan-item {
	font-size: 16px;
	padding-bottom: 10px;
}

.account-billing-container .billing-overview iframe {
	width: 100%;
}
