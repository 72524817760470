.contact-lists-list-container {
	padding: 20px 0;
	flex: 1 1;
}

.contact-lists-list-container table thead {
	position: sticky;
	top: 0;
}

.contact-lists-list-container table tr.active {
	background-color: var(--list-selected-row);
}

.contact-lists-list-container table td.settings-action {
	text-align: right;
}

.contact-lists-list-container table td .btn.btn-link {
	color: #555555;
}
