.broadcasts-form-container {
	flex: 1 1;
	padding: 0 20px;
	height: calc(100% - 65px);
}

.broadcasts-form-container .app-scrollable-container-content {
	padding: 20px;
	border: 1px solid var(--default-border-color);
}

.broadcasts-form-container form {
	width: 100%;
}

.broadcasts-form-container form .form-inputs {
	/* padding: 5px; */
}

.broadcasts-form-container textarea {
	width: 100%;
	border-radius: 10px;
	resize: none;
}

.broadcasts-form-container .message-body textarea {
	border-radius: 10px 10px 0 0;
}

.broadcasts-form-container .container-buttons {
	margin-top: 20px;
}

.broadcasts-form-container .btn-broadcast-type {
	min-width: 150px;
}

.broadcasts-form-container .send-message-button {
	margin-top: 10px;
}

.broadcasts-form-container .send-message-actions {
	border: 1px solid var(--default-border-color);
	border-top: 0;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.broadcasts-form-container .send-message-actions .send-message-action {
	display: inline-block;
	border-right: 1px solid var(--default-border-color);
}

.broadcasts-form-container .send-message-actions .send-message-action:first-child,
.broadcasts-form-container .send-message-actions .send-message-action:first-child button.btn-primary {
	border-bottom-left-radius: 10px;
}

.broadcasts-form-container .send-message-actions .send-message-action button.btn-link {
	text-decoration: none;
	color: #8f8f8f;
	padding: 0.25rem 0.5rem;
	font-size: 14px;
}

.broadcasts-form-container .send-message-actions .send-message-action button {
	text-decoration: none;
	border-radius: 0;
	outline: 0;
}

.broadcasts-form-container .send-message-actions .send-message-action .message-type {
	display: inline-block;
	font-weight: 400;
	line-height: 1.5;
	border: 1px solid transparent;
	padding: 0.25rem 0.5rem;
	font-size: 14px;
	vertical-align: middle;
}

.broadcasts-form-container .send-message-actions .send-message-action .message-type.active {
	background-color: var(--primary-color);
	color: #ffffff;
}

.broadcasts-form-container .send-message-actions .send-message-action:last-child {
	border-right: 1px solid var(--default-border-color);
}

.broadcasts-form-container .send-message-attachments-area {
	border: 1px solid var(--default-border-color);
	border-top: 0;
	padding: 5px 10px;
}

.broadcasts-form-container .send-message-attachments-area .attachment-item {
	display: inline-block;
	position: relative;
	font-size: 13px;
	padding: 1px 5px 0 5px;
	margin-right: 10px;
	background-color: #e4e4e4;
	border-radius: 5px;
}

.broadcasts-form-container .send-message-attachments-area .attachment-item a {
	text-decoration: none;
	color: #707070;
}

.broadcasts-form-container .send-message-attachments-area .attachment-item .attachment-item-remove {
	color: #ff0000;
	padding: 0;
	margin: 0;
	margin-left: 10px;
	margin-top: -1px;
	font-size: 14px;
}

.broadcasts-form-container .broadcast-stats-wrapper {
	display: flex;
	flex-wrap: wrap;
}

.broadcasts-form-container .broadcast-stats-item {
	border: 1px solid rgba(0, 27, 72, .12);
	border-radius: 8px;
	flex-basis: 0;
	flex-grow: 1;
	margin-right: 8px;
	padding: 12px 16px 8px;
}

.broadcasts-form-container .broadcast-stats-item-value {
	font-size: 20px;
	line-height: 30px;
	margin-top: 10px;
}

@media screen and (max-width: 767px) {
	.broadcasts-form-container {
		padding: 0;
	}

	.broadcasts-form-container .app-scrollable-container-content {
		padding: 10px;
	}
}
