.analytics-container {
}

.analytics-section {
	/* width: 50%; */
	padding: 20px 0;
}

.analytics-item {
	width: 100%;
	padding: 20px 10px;
	margin: 0;
	border: 1px solid var(--default-border-color);
	border-radius: var(--default-border-radius);
}

.analytics-item-title {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 20px;
}

.analytics-value {
	font-size: 18px;
	font-weight: bold;
}

@media screen and (max-width: 767px) {
	.analytics-container {
		width: 100%;
	}
}
