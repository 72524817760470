.app-integration-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	padding: 0;
}

.app-integration-container .account-integration-container {
	flex: 1;
}

.app-integration-container .account-integration-container .app-scrollable-container-header {
	padding: 30px 40px 10px 40px;
}

.app-integration-container .account-integration-container .app-scrollable-container-content {
	display: flex;
	flex-direction: column;
	padding: 0px 40px 0 40px;
}

.account-integration-container .form-group,
.account-integration-container .input-group {
	width: 50%;
}

@media screen and (max-width: 767px) {
	.account-integration-container {
		padding: 20px 0;
	}

	.account-integration-container .form-group,
	.account-integration-container .input-group {
		width: 100%;
	}

	.account-integration-container .input-group .form-control:disabled,
	.form-control[readonly] {
		padding-right: 40px;
	}
}
