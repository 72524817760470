.inbox-contact-form .contact-status,
.modal-dialog-content .contact-status {
	height: 30px;
	font-size: 16px;
	color: #ffffff;
	padding: 2px 10px;
	border-radius: 5px;
	margin-top: -3px;
}

.inbox-contact-form .contact-status.active,
.modal-dialog-content .contact-status.active {
	background-color: var(--contact-active-color);
}

.inbox-contact-form .contact-status.invalid,
.modal-dialog-content .contact-status.invalid {
	background-color: var(--contact-invalid-color);
}

.inbox-contact-form .contact-status.unsubscribed,
.modal-dialog-content .contact-status.unsubscribed {
	background-color: var(--contact-unsubscribed-color);
}
