.app-administration-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	gap: var(--default-margin);
}

.app-administration-container .administration-container {
	background-color: #ededed;
	border-radius: 15px;
	min-width: 25%;
	height: calc(100% - 65px);
}

.app-administration-container .administration-content-container {
	flex: 1;
}

.app-administration-container .administration-content-container .app-scrollable-container-content {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.app-administration-container .administration-content-container .app-scrollable-container-content .footer-container {
	flex-basis: 100%;
	padding-bottom: 0;
}

@media screen and (max-width: 767px) {
	.app-administration-container {
		flex-direction: column;
	}

	.app-administration-container .administration-content-container .app-scrollable-container-content {
		flex-direction: column;
		flex: unset;
		background-color: transparent;
	}

	.app-administration-container .administration-content-container .app-scrollable-container-header {
		display: none;
	}
}
