@font-face {
	font-family: 'Avenir Black';
	src: url('fonts/Avenir Black.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Medium';
	src: url('fonts/Avenir Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Roman';
	src: url('fonts/Avenir-Roman.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

:root {
	/* App CSS vars will be stored here */
	--default-font: "Avenir Medium";
	--default-font-bold: "Avenir Black";
	--primary-color: #0b67fa;
	--default-font-color: #343434;
	--main-app-color: #208bb9;
	--default-margin: 30px;
	--default-border-color: #ddd;
	--default-border-radius: 4px;
	--default-transition: all .3s ease-out;
	--modal-dialog-background-color: white;
	--modal-buttons-shaded-color: #f5f6fa;
	--modal-buttons-shaded-border-color: #ddd;
	--tag-background-color: #e6eef5;
	--contact-active-color: #2b7a43;
	--contact-invalid-color: #d12b4f;
	--contact-unsubscribed-color: #af31bb;
	--list-selected-row: #e2e3e5;
}

html,
body,
#root,
.app-container {
	height: 100%;
	color: var(--default-font-color);
	font-family: var(--default-font);
}

.panel {
	padding: var(--default-margin);
	height: 100%;
}

.btn-link:focus {
	box-shadow: none;
}

.section-divider-title {
	padding: 20px 0 10px 0;
	margin-bottom: 20px;
	border-bottom: 1px solid var(--default-border-color);
	font-size: 22px;
}

.form-header-title {
	font-size: 24px;
	font-weight: bold;
	/* border-bottom: 1px solid var(--default-border-color); */
	/* padding-bottom: 10px; */
	margin-bottom: 15px;
}

.form-inputs .form-group label {
	margin-bottom: 5px;
}

.no-data {
	padding: 10px;
	text-align: center;
}

::placeholder {
	color: #adadad !important;
}

.dropdown-menu {
	border-radius: var(--default-border-radius) !important;
	/* min-width: 8rem; */
}

.dropdown-menu .btn.dropdown-item i {
	font-size: 14px;
}

.btn-copy-input {
	position: absolute !important;
	top: 0px;
	right: 0px;
	padding-right: 0;
}

.btn-copy-input i {
	font-size: 16px;
	color: var(--default-font-color);
}

/* react-select component */
.react-select-component .react-select__multi-value {
	background-color: var(--tag-background-color);
	align-items: center;
	border-radius: 0.375rem;
	display: inline-flex;
	justify-content: center;
	padding: 0;
}

.react-select-component .react-select__multi-value__label {
	font-size: 90%;
}

.react-select-component .react-select__multi-value__remove svg {
	width: 18px;
	height: 18px;
}

.react-select-component .react-select__multi-value__remove:hover {
	background-color: unset;
}

/* tags input component */
.rti--container .rti--input {
	width: 100px;
}

.rti--container .rti--tag {
	background: var(--tag-background-color);
	font-size: 90%;
}

.rti--container .rti--tag button {
	font-size: 11px;
	font-weight: bold;
}

/* CodeMirror */
.cm-editor,
.cm-scroller {
	border-radius: 5px !important;
}

.cm-editor.cm-focused {
	outline: none !important;
}

.cm-content {
	white-space: pre-wrap !important;
	line-break: anywhere;
}

.flex-fill-remaining-space {
	flex: auto;
}

@media screen and (max-width: 767px) {
	:root {
		--default-margin: 20px;
	}

	body {
		font-size: 14px;
	}

	/* .panel {
		padding-top: 30px;
		padding-bottom: 50px;
	} */

	.btn:not(.btn-lg) {
		padding: .3rem .4rem;
		font-size: .8rem;
	}

	.form-control {
		font-size: 1rem;
	}

	.form-control.form-control-lg {
		min-height: 38px;
	}

	.form-header-title {
		font-size: 20px;
	}
}
