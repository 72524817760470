.contact-lists-content-header-container {
	display: flex;
	color: var(--default-font-color);
}

.contact-lists-content-header-add-button {
	margin-left: 5px;
}

.contact-lists-content-header-search {
	position: relative;
	flex: 1 1;
}

.contact-lists-content-header-container .contact-lists-list-title {
	padding-top: var(--default-margin);
}
