.contacts-sidebar-container {
	background-color: #ededed;
	border-radius: 15px;
	min-width: 25%;
	height: calc(100% - 65px);
}

.contacts-sidebar-container .container {
	align-self: flex-start;
}

.contacts-sidebar-container .app-scrollable-container-content {
	padding: var(--default-margin);
	display: flex;
	align-items: center;
	background-color: #ededed;
	border-radius: 15px;
	/* min-width: 25%; */
}

.contacts-sidebar-container .contacts-item {
	padding: 7px;
	margin-bottom: 5px;
	/* display: grid; */
	font-size: 14px;
	cursor: pointer;
}

.contacts-sidebar-container .contacts-item.active {
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.contacts-sidebar-container .contacts-item-label .btn-link {
	width: 100%;
	font-size: 20px;
	font-weight: bold;
	color: #5a5a5a;
	text-decoration: none;
	text-align: left;
	padding: 0;
	vertical-align: middle;
}

.contacts-sidebar-container .contacts-item-count {
	text-align: center;
	margin-top: 6px;
	display: flex;
	justify-content: center;
}

.contacts-sidebar-container .contacts-item-count span {
	padding: 0px 5px;
	width: auto;
	height: 22px;
	background: #52cdb1;
	color: #fff;
	border-radius: 5px;
	vertical-align: middle;
}

@media screen and (max-width: 767px) {
	.contacts-sidebar-container {
		height: auto;
		background-color: transparent;
	}

	.contacts-sidebar-container .app-scrollable-container-content {
		padding: 0;
		border-radius: 0;
	}

	.contacts-sidebar-container .container {
		padding: 0;
	}

	.contacts-sidebar-container .contacts-item {
		padding: 0;
		margin-bottom: 0;
		margin-right: 10px;
		display: inline-block;
	}

	.contacts-sidebar-container .contacts-item.active {
		box-shadow: none;
		background-color: transparent;
		border-radius: 0;
	}

	.contacts-sidebar-container .contacts-item-label .btn-link {
		font-weight: normal;
	}

	.contacts-sidebar-container .contacts-item.active .contacts-item-label .btn-link {
		border-radius: 0;
		border-bottom: 3px solid var(--main-app-color);
	}

	.contacts-item-count {
		display: none;
	}
}
