.inbox-messages-container {
	padding: 0 20px;
}

.inbox-messages-container .app-scrollable-container-content {
	padding: var(--default-margin);
	margin-top: 50px;
}

.inbox-contact-container {
	width: 25%;
	height: calc(100% - 65px);
	border-left: 1px solid #ddd;
}

@media screen and (max-width: 767px) {
	.inbox-messages-container {
		padding: 5px;
	}
}
