.profile-details-container {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
	width: 100%;
	/* height: 100%; */
}

.profile-details-container .btn-group > button {
	border: 0;
	background-color: transparent;
}

.profile-details-container .dropdown-menu .detail {
	padding: 0.5rem 1rem;
	border-bottom: 1px solid var(--default-border-color);
}

.profile-details-container .dropdown-menu .detail .email {
	font-size: 14px;
}

.profile-details-inner {
	width: 100%;
	display: flex;
	padding: 10px 10px 0 10px;
	border-top: 1px solid var(--default-border-color);
}

.profile-details-inner .css-ta6kqf-MuiAvatar-root {
	font-family: var(--default-font) !important;
}

.profile-details-inner .css-e53awj-MuiStack-root {
	align-items: center;
}

.profile-details-container .profile-details {
	text-transform: uppercase;
	width: 100%;
	height: 100%;
	margin-top: 3px;
	margin-left: 20px;
}

.profile-details-container .profile-details-link {
	text-decoration: none;
	color: var(--nav-links-color);
}

.profile-details-container .profile-details .full-name {
	font-weight: bold;
}

.profile-details-container a.logout-link {
	width: 100%;
	padding: 5px 15px 0 15px;
	text-align: left;
	text-decoration: none;
	color: var(--nav-links-color);
}

.profile-details-container a.logout-link span {
	text-transform: uppercase;
	font-weight: bold;
	margin-left: 23px;
}

.profile-details-container a.logout-link img {
	height: 30px;
}
