:root {
	--nav-links-color: #343434;
}

.app-left-nav-links {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: var(--default-margin);
	padding: 5px;
	margin: 15px 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
}

.app-left-nav-links .app-left-nav-link {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;
	position: relative;
	width: 100%;
	color: var(--nav-links-color);
	text-decoration: none;
	transition: var(--default-transition);
}

.app-left-nav-links .app-left-nav-link .app-left-nav-link-icon {
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	border-left: 5px solid transparent;
	transition: var(--default-transition);
}

.app-left-nav-links .app-left-nav-link .app-left-nav-link-icon img {
	width: 25px;
	height: 25px;
	margin-left: 10px;
}

.app-left-nav-links .app-left-nav-link .app-left-nav-link-icon i {
	font-size: 18px;
}

.app-left-nav-links .app-left-nav-link .app-left-nav-link-text {
	position: relative;
	top: 3px;
	font-family: 'Avenir Black';
	font-size: 16px;
	text-transform: uppercase;
	line-height: 30px;
	margin-left: 10px;
}

.app-left-nav-links .app-left-nav-link .app-left-nav-link-arrow {
	position: absolute;
	right: 0;
	font-size: 18px;
}

.app-left-nav-links .app-left-nav-link .app-left-nav-link-unread-messages {
	position: absolute;
	right: 20px;
	font-size: 12px;
	padding: 1px 5px 0px 5px;
	background-color: #ff0000;
	color: #ffffff;
}

.app-left-nav-links .app-left-nav-link.active .app-left-nav-link-icon {
	border-left: 5px solid var(--primary-color);
}
