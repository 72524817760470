.contacts-content-header-container {
	display: flex;
	color: var(--default-font-color);
}

.contacts-content-header-add-button {
	margin-left: 5px;
}

.contacts-content-header-search {
	position: relative;
	flex: 1 1;
}

.contacts-content-header-container .contacts-list-title {
	padding-top: var(--default-margin);
}
