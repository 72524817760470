.footer-container .footer-links ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.footer-container .footer-links ul li {
	display: inline;
	margin-right: 10px;
	font-size: 14px;
}

@media screen and (max-width: 767px) {
	.footer-container .footer-links ul li {
		margin-right: 5px;
		font-size: 13px;
	}
}
