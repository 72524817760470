.broadcasts-sidebar-header.broadcasts-sidebar-header-placeholder {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: var(--default-margin);
	border-bottom: 1px solid var(--default-border-color);
	gap: var(--default-margin);
}

.broadcasts-sidebar-header.broadcasts-sidebar-header-placeholder .broadcasts-sidebar-header-text {
	flex: 1;
}
