.app-dashboard-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	padding: 0;
}

.app-dashboard-container .dashboard-content-container {
	flex: 1;
}

.app-dashboard-container .dashboard-content-container .app-scrollable-container-header {
	padding: 30px 40px 10px 40px;
}

.app-dashboard-container .dashboard-content-container .app-scrollable-container-content {
	display: flex;
	flex-direction: column;
	padding: 30px 40px 0 40px;
}

.app-dashboard-container .dashboard-welcome-note {
	font-size: 18px;
	margin-bottom: 10px 0;
}

.app-dashboard-container .dashboard-group-title {
	padding: 5px 0;
	margin-top: 20px;
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
}

.app-dashboard-container .dashboard-group-title:first-child {
	margin-top: 0;
}

.app-dashboard-container .dashboard-group {
	display: flex;
	flex-direction: row;
	gap: var(--default-margin);
	padding: 5px 0;
}

.app-dashboard-container .dashboard-group .dashboard-group-item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	min-width: 280px;
	min-height: 180px;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

.app-dashboard-container .dashboard-group .dashboard-group-item-link {
	text-decoration: none;
	color: var(--default-color);
}

.app-dashboard-container .dashboard-group .dashboard-group-item-link:hover .dashboard-group-item {
	text-decoration: none;
	background-color: #f0f0f0;
}

.app-dashboard-container .dashboard-group .dashboard-group-item .dashboard-group-item-title {
	font-size: 40px;
	font-weight: bold;
}

.app-dashboard-container .dashboard-group .dashboard-group-item .dashboard-group-item-icon img {
	width: 70px;
	height: 70px;
	margin-bottom: 10px;
}

.app-dashboard-container .dashboard-group .dashboard-group-item .dashboard-group-item-subtitle {
	font-size: 18px;
}

.app-dashboard-container .dashboard-group .dashboard-group-item .dashboard-group-item-note {
	position: absolute;
	bottom: 10px;
}

.app-dashboard-container .dashboard-group .dashboard-group-item .dashboard-group-item-note .btn {
	font-size: 14px;
}

@media screen and (max-width: 767px) {
	.app-dashboard-container .dashboard-content-container .app-scrollable-container-content {
		display: flex;
		padding: 20px;
	}

	.app-dashboard-container .dashboard-group {
		flex-direction: column;
	}

	.app-dashboard-container .dashboard-content-container .app-scrollable-container-header {
		padding: 20px;
	}
}
