.app-inbox-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
}

.app-inbox-container .inbox-message-threads-container {
	background-color: #ededed;
	border-radius: 15px;
	min-width: 25%;
	height: calc(100% - 65px);
}

.app-inbox-container .inbox-content-container {
	flex: 1;
}

.app-inbox-container .inbox-content-messages-container {
	flex: 1;
	height: calc(100% - 65px);
}

.app-inbox-container .inbox-content-messages-container.contact-view {
	padding: 0;
}

.app-inbox-container .footer-container {
	flex-basis: 100%;
	padding-bottom: 0;
}

.app-inbox-container .inbox-content-container .app-scrollable-container-content {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	overflow-x: hidden;
}

@media screen and (max-width: 767px) {
	.app-inbox-container {
		width: 100%;
		padding: 10px;
	}

	.app-inbox-container .inbox-content-container {
		width: 100%;
	}

	.app-inbox-container .inbox-message-threads-container {
		width: 100%;
		position: relative;
		flex: 1 0 auto;
		min-width: unset;
	}
}
