.keyword-item-container {
	padding: 15px 10px;
	margin-bottom: 2px;
	font-size: 14px;
	cursor: pointer;
}

.keyword-item-container.active {
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.keyword-item-container a {
	text-decoration: none;
	color: var(--default-font-color);
}

.keyword-item-wrapper {
	display: grid;
	grid-template-columns: 70% 30%;
	padding: 0px 5px;
}

.keyword-item-wrapper .keyword-name {
	grid-row: 1;
	grid-column: 1;
	font-weight: bold;
}

.keyword-item-wrapper .keyword-status {
	grid-row: 1;
	grid-column: 2;
	display: flex;
	justify-content: flex-end;
	padding: 0px 5px;
}

.keyword-item-wrapper .keyword-contacts-count {
	grid-row: 2;
	grid-column: 1;
	display: flex;
}
