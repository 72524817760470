.zapier-documentation p {
	padding: 5px 0;
	font-size: 18px;
}

.zapier-documentation h4 {
	margin-top: 30px;
}

.zapier-documentation img {
	height: auto;
	max-width: 100%;
	border-style: solid;
	border: 1px solid #333333;
	background-clip: border-box;
	box-shadow: 0px 0px 5px 0px #5e5e5e;
	padding: 10px 0;
}
