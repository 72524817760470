.customcontent-form-container {
	flex: 1 1;
	padding: 0 20px;
	height: calc(100% - 65px);
}

.customcontent-form-container .app-scrollable-container-content {
	padding: 20px;
	border: 1px solid var(--default-border-color);
}

.customcontent-form-container form {
	width: 100%;
}

.customcontent-form-container form .form-inputs {
	padding: 5px;
}

.customcontent-form-container .html-editor {
	width: 100%;
	border-radius: 5px;
	resize: none;
	border: 1px solid var(--default-border-color);
}

.customcontent-form-container .container-buttons {
	margin-top: 20px;
}

.customcontent-form-container .container-buttons .btn {
	margin-right: 10px;
}

.customcontent-form-container .form-check-input:focus {
	box-shadow: none;
}

.icon-preview img {
	width: auto;
	height: 25px;
}

@media screen and (max-width: 767px) {
	.customcontent-form-container {
		padding: 0;
	}

	.customcontent-form-container .app-scrollable-container-content {
		padding: 10px;
	}
}
