.inbox-messages-header-container {
	margin-bottom: 15px;
}

.inbox-messages-header-title {
	font-size: 24px;
	font-weight: bold;
	position: relative;
}

.inbox-messages-header-title input {
	padding: 15px;
}

.inbox-messages-header-title .btn-cancel {
	position: absolute;
	top: 10px;
	right: 10px;
	color: var(--default-font-color);
	text-decoration: none;
	font-size: 14px;
}

.inbox-messages-header-action-buttons button i {
	font-size: 20px;
	margin-right: 0px;
	color: #4b4b4b;
}

.inbox-messages-header-title .contacts-select {
	position: relative;
	font-size: 20px;
	font-weight: normal;
	margin-top: 4px;
}

.inbox-messages-header-title .contacts-select .contacts__control {
	border-radius: 10px;
	box-shadow: none;
}

.inbox-messages-header-title .contacts-select .contacts__control--is-focused {
	border-color: #86b7fe;
	box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.inbox-messages-header-title .contacts-select .contacts__value-container {
	padding: 5px 10px;
}

.inbox-messages-header-title .contacts-select .contacts__indicators {
	display: none;
}

@media screen and (max-width: 767px) {
	.inbox-messages-header-title {
		font-size: 18px;
		margin-top: 3px;
	}
}
