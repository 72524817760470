.submit-thank-you-title {
	margin-top: 100px;
	font-size: 36px;
	font-weight: bold;
	text-align: center;
}

.submit-thank-you-content {
	margin-top: 20px;
	font-size: 24px;
	text-align: center;
}
