.message-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 8px 10px;
	color: #4b4b4b;
}

.message-container.outbound {
	align-items: flex-end;
	text-align: end;
}

.message-container .message-body {
	position: relative;
	padding: 15px;
	font-size: 14px;
	background-color: #eaeaea;
	border-radius: 15px;
	overflow-wrap: anywhere;
}

.message-container.outbound .message-body {
	background-color: #d3ebfc;
}

.message-container .delete-message {
	display: none;
	position: absolute;
	top: calc(50% - 13px);
	right: -30px;
	left: unset;
}

.message-container .message-body:hover .delete-message {
	display: inline;
}

.message-container.outbound .delete-message {
	left: -32px;
	right: unset;
}

.message-container .delete-message button {
	color: var(--default-font-color);
	text-decoration: none;
}

.message-container .delete-message button i {
	margin-left: 10px;
}

.message-container .message-time {
	padding: 5px;
	font-size: 12px;
	color: #a1a1a1;
}

.message-container .message-attachments {
	padding-top: 15px;
}

.message-container .message-attachments .message-attachment-item {
	display: inline-block;
	margin-right: 10px;
	border: 1px solid var(--default-border-color);
}

.message-container .message-attachments .message-attachment-item .file {
	font-size: 13px;
	padding: 3px 5px;
	background-color: #e2e2e2;
	border-radius: 5px;
}

.message-container .message-attachments .message-attachment-item .image {
	border-radius: 5px;
}

.message-container .message-attachments .message-attachment-item .image img {
	width: 80px;
	height: auto;
}

.message-container .message-attachments .message-attachment-item:last-child {
	margin-right: 0;
}

.message-container .message-attachments .message-attachment-item a {
	text-decoration: none;
	color: #707070;
}

.message-container .message-attachments .message-attachment-item a i {
	font-size: 16px;
	margin-right: 10px;
}

.message-container .message-attachments .message-attachment-item a i.fa-file-pdf {
	color: #cc4b4c;
}

.message-container .message-attachments .message-attachment-item a i.fa-file-word {
	color: #2c5899;
}

.message-container .message-attachments .message-attachment-item a i.fa-file-excel {
	color: #1f7244;
}

.message-container .message-attachments .message-attachment-item a i.fa-file-csv {
	color: #1f7244;
}

.message-container .message-attachments .message-attachment-item a i.fa-file {
	/* color: #1f7244; */
}
