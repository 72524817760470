.inbox-messages-footer-container {
	margin-top: var(--default-margin);
}

.inbox-messages-footer-container .send-message-text {
	position: relative;
}

.inbox-messages-footer-container .send-message-text textarea {
	width: 100%;
	border-radius: 10px 10px 0 0;
	resize: none;
}

.inbox-messages-footer-container .send-message-area {
	margin-top: 10px;
}

.inbox-messages-footer-container .send-message-area .user-credits {
	font-weight: bold;
}

.inbox-messages-footer-container .send-message-area .user-credits.warning {
	color: #d82020;
}

.inbox-messages-footer-container .send-message-actions {
	border: 1px solid var(--default-border-color);
	border-top: 0;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.inbox-messages-footer-container .send-message-actions .send-message-action {
	display: inline-block;
	border-right: 1px solid var(--default-border-color);
}

.inbox-messages-footer-container .send-message-actions .send-message-action:first-child,
.inbox-messages-footer-container .send-message-actions .send-message-action:first-child button.btn-primary {
	border-bottom-left-radius: 10px;
}

.inbox-messages-footer-container .send-message-actions .send-message-action button.btn-link {
	text-decoration: none;
	color: #8f8f8f;
	padding: 0.25rem 0.5rem;
	font-size: 14px;
}

.inbox-messages-footer-container .send-message-actions .send-message-action button {
	text-decoration: none;
	border-radius: 0;
	outline: 0;
}

.inbox-messages-footer-container .send-message-actions .send-message-action .message-type {
	display: inline-block;
	font-weight: 400;
	line-height: 1.5;
	border: 1px solid transparent;
	padding: 0.25rem 0.5rem;
	font-size: 14px;
	vertical-align: middle;
}

.inbox-messages-footer-container .send-message-actions .send-message-action .message-type.active {
	background-color: var(--primary-color);
	color: #ffffff;
}

.inbox-messages-footer-container .send-message-actions .send-message-action:last-child {
	border-right: 1px solid var(--default-border-color);
}

.inbox-messages-footer-container .send-message-attachments-area {
	border: 1px solid var(--default-border-color);
	border-top: 0;
	padding: 5px 10px;
}

.inbox-messages-footer-container .send-message-attachments-area .attachment-item {
	display: inline-block;
	position: relative;
	font-size: 13px;
	padding: 1px 5px 0 5px;
	margin-right: 10px;
	background-color: #e4e4e4;
	border-radius: 5px;
}

.inbox-messages-footer-container .send-message-attachments-area .attachment-item a {
	text-decoration: none;
	color: #707070;
}

.inbox-messages-footer-container .send-message-attachments-area .attachment-item .attachment-item-remove {
	color: #ff0000;
	padding: 0;
	margin: 0;
	margin-left: 10px;
	margin-top: -1px;
	font-size: 14px;
}
