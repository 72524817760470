.sidebar-collapse-button {
	position: absolute;
	top: 40px;
	left: -13px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 26px;
	height: 26px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%;
	text-decoration: none;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 4px;
	z-index: 99;
}

.sidebar-collapse-button i {
	color: var(--nav-links-color);
	font-size: 14px;
}
