.modal-dialog-buttons {
	padding: calc(var(--default-margin) / 2);
}

.modal-dialog-buttons.shaded {
	background-color: var(--modal-buttons-shaded-color);
	border-top: 1px solid var(--modal-buttons-shaded-border-color);
}

.modal-dialog-buttons .btn {
	margin-right: 10px;
}

.modal-dialog-buttons .btn:last-child {
	margin-right: 0;
}
