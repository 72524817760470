.message.message-placeholder {
	padding: calc(var(--default-margin) / 2);
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	gap: var(--default-margin);
}

.message.message-placeholder .icon .icon-circle {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.message.message-placeholder .content {
	flex: 1;
}
