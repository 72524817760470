.media-list-container {
	height: 100%;
}

.media-item-container {
	padding: 5px;
	margin-bottom: 3px;
	cursor: pointer;
	border: 1px solid transparent;
	width: 100%;
	/* border-radius: 10px; */
}

.media-item-container.active,
.media-item-container:hover {
	background-color: #eee;
}

.media-item-container .media-item-name {
	padding: 0;
}

.media-item-container .media-item-checked i {
	display: none;
	font-size: 18px;
	color: #229e52;
	margin-top: 5px;
}

.media-item-container.active .media-item-checked i {
	display: block;
}
