.content-header-container {
	margin-bottom: 15px;
}

.content-header-container .content-area-title {
	font-size: 30px;
	font-family: var(--default-font-bold);
}

.content-header-container .content-area-actions {
	text-align: right;
}

.content-header-container .no-credits-warning {
	background-color: #cc2e2e;
	color: #ffffff;
	font-size: 18px;
	padding: 10px;
	text-align: center;
	width: 100%;
	border-radius: 10px;
}

@media screen and (max-width: 767px) {
	.content-header-container .content-area-actions,
	.app-left-nav-container,
	.app-left-nav-header-collapse-button {
		display: none;
	}

	.content-header-container {
		margin-bottom: 5px;
	}

	.content-header-container .content-area-title {
		font-size: 20px;
	}
}
