.webforms-form-container {
	flex: 1 1;
	padding: 0 20px;
	height: calc(100% - 65px);
}

.webforms-form-container .app-scrollable-container-content {
	padding: 20px;
	border: 1px solid var(--default-border-color);
}

.webforms-form-container form {
	width: 100%;
}

.webforms-form-container form .form-inputs {
	padding: 5px;
}

.webforms-form-container textarea {
	width: 100%;
	border-radius: 10px;
	resize: none;
}

.webforms-form-container .message-body textarea {
	border-radius: 10px 10px 0 0;
}

.webforms-form-container .container-buttons {
	margin-top: 20px;
}

.webforms-form-container .container-buttons .btn {
	margin-right: 10px;
}

.webforms-form-container .send-message-button {
	margin-top: 10px;
}

.webforms-form-container .form-check-input:focus {
	box-shadow: none;
}

@media screen and (max-width: 767px) {
	.webforms-form-container {
		padding: 0;
	}

	.webforms-form-container .app-scrollable-container-content {
		padding: 10px;
	}
}
