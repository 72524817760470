.contacts-content-area {
	flex: 1 1;
	padding: 0 20px;
	height: calc(100% - 65px);
}

@media screen and (max-width: 767px) {
	.contacts-content-area {
		padding: 0;
	}
}
