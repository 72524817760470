.broadcast-message-link a {
	color: var(--default-font-color);
	text-decoration: none;
}

.broadcast-message-date {
	position: relative;
	margin-left: 20px;
	top: 2px;
}

.inbox-contact-form {
	height: 100%;
	width: 100%;
	padding-top: 5px;
}

.inbox-contact-form .app-scrollable-container-content,
.inbox-contact-form .app-scrollable-container-header {
	padding: 0px 20px;
}

.inbox-contact-form .app-scrollable-container-content form {
	width: 100%;
}

.inbox-contact-form-buttons {
	padding: 10px 20px 0 20px;
}

.inbox-contact-form-buttons .btn {
	margin-right: 10px;
}
