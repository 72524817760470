.app-account-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	padding: 0;
	/* gap: var(--default-margin); */
}

.app-account-container .account-container {
	background-color: #ededed;
	border-radius: 15px;
	min-width: 25%;
}

.app-account-container .account-content-container {
	flex: 1;
}

.app-account-container .account-content-container .app-scrollable-container-header {
	padding: 30px 30px 10px 30px;
}

.app-account-container .account-content-container .app-scrollable-container-content {
	display: flex;
	flex-direction: column;
	padding: 0px 30px 0 30px;
}

.app-account-container .account-content-container .account-content-tabs {
	padding: 0px;
}

.app-account-container .account-content-container .nav-tabs .nav-link {
	width: 250px;
	border: none;
	text-transform: uppercase;
	color: var(--default-font-color);
	text-align: center;
}

.app-account-container .account-content-container .nav-tabs .nav-item.show .nav-link,
.app-account-container .account-content-container .nav-tabs .nav-link.active {
	border-bottom: 5px solid var(--main-app-color);
	background-color: transparent;
}

@media screen and (max-width: 767px) {
	.account-content-tabs .nav {
		flex-direction: column;
	}

	.app-account-container .account-content-container .nav-tabs .nav-link {
		width: 100%;
	}
}
