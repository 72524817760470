.app-left-nav-container {
	position: relative;
	width: 300px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: calc(var(--default-margin) / 2);
	border-right: 1px solid var(--default-border-color);
	transition: var(--default-transition);
}

.app-left-nav-container.collapsed {
	width: 100px;
	transition: var(--default-transition);
}

.app-left-nav-container.collapsed .app-left-nav-links {
	width: 70px;
}

.app-left-nav-container.collapsed .app-left-nav-header .app-logo {
	padding-bottom: 80px;
}

.app-left-nav-container .app-left-nav-header .app-logo img {
	content: url('../../../img/Mindfulsms-logo.png');
	max-height: 35px;
}

.app-left-nav-container.collapsed .app-left-nav-header .app-logo img {
	content: url('../../../img/logo.png');
	max-height: 50px;
}

.app-left-nav-container.collapsed .app-left-nav-header .app-left-nav-header-text,
.app-left-nav-container.collapsed .app-left-nav-header .app-left-nav-header-number,
.app-left-nav-container.collapsed .app-left-nav-links .app-left-nav-link .app-left-nav-link-text,
.app-left-nav-container.collapsed .app-left-nav-links .app-left-nav-link .app-left-nav-link-arrow,
.app-left-nav-container.collapsed .profile-details,
.app-left-nav-container.collapsed .profile-details-container a.logout-link span {
	display: none;
}

.app-left-nav-container.collapsed .app-left-nav-links .app-left-nav-link .app-left-nav-link-unread-messages {
	right: -5px;
}

.app-left-nav-container .app-left-nav-header-collapse-button {
	position: absolute;
	top: 35px;
	right: -14px;
	padding: 0px 6px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%;
	text-align: center;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 4px;
}

.app-left-nav-container .app-left-nav-header-collapse-button i {
	color: var(--nav-links-color);
	font-size: 14px;
}

.app-left-nav-separator {
	width: 100%;
	margin: 0;
}

.nav-mobile {
	display: none;
}

@media screen and (max-width: 767px) {
	.nav-mobile {
		display: flex;
		width: 100%;
		padding: 10px;
		border-bottom: 1px solid var(--default-border-color);
	}

	.nav-mobile-button {
		border: 0;
		background-color: transparent;
	}

	.nav-mobile-button i {
		font-size: 42px;
		min-width: 42px;
	}

	.nav-mobile-logo {
		width: 50px;
		height: 50px;
		margin-bottom: 3px;
		margin-left: 5px;
	}

	.nav-menu-mobile-wrapper {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 75px;
		width: 250px;
		padding: 10px;
		height: calc(100% - 75px);
		background-color: #fff;
		border-right: 1px solid var(--default-border-color);
		z-index: 1;
		transition: var(--default-transition);
	}

	.nav-menu-mobile-wrapper .app-left-nav-header .app-logo {
		display: none;
	}

	.nav-menu-mobile-wrapper.collapsed {
		display: none;
		transition: var(--default-transition);
	}

	.admin-link-mobile {
		padding: 10px 0;
		margin-right: 10px;
		margin-top: 7px;
	}

	.admin-link-mobile i {
		color: var(--default-font-color);
		font-size: 20px;
	}
}
