.inbox-sidebar-header-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 20px;
	border-bottom: 1px solid var(--default-border-color);
	color: var(--default-font-color);
}

.inbox-sidebar-header-add-button {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	margin-left: 5px;
}

.inbox-sidebar-header-search {
	position: relative;
	max-width: 160px;
}

.inbox-sidebar-header-select {
	display: flex;
}

.inbox-sidebar-header-container select {
	margin-left: 5px;
	border-radius: 10px;
}

@media screen and (max-width: 767px) {
	.inbox-sidebar-header-search {
		position: relative;
		width: 100%;
		max-width: 45%;
	}

	/* .inbox-sidebar-header-select {
		width: 90px;
	} */
}
