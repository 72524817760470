.quick-actions-btn-wrapper .btn {
	background-color: var(--main-app-color);
	border: 1px solid var(--main-app-color);
	text-transform: uppercase;
}

.quick-actions-btn-wrapper .btn.dropdown-toggle::after {
	display: none;
}

.quick-actions-btn-wrapper .btn.dropdown-toggle i {
	margin-left: 10px;
}

@media screen and (max-width: 767px) {
	.quick-actions-btn-wrapper {
		display: flex;
		padding: 10px 0;
	}

	.quick-actions-btn-wrapper .btn.dropdown-toggle i {
		margin-right: 0;
	}
}
