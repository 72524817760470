.change-plan-container {
	/* width: 50%;
	display: flex;
	justify-content: center; */
	padding: 20px 10px;
}

.change-plan-container .plan-selector {
	text-align: center;
}

.change-plan-container table tr.active {
	background-color: #ddd;
}

.change-plan-container table td.settings-action {
	text-align: right;
}
