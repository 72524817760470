.web-form-item-wrapper .dnd-card {
	display: grid;
	grid-template-columns: 30px auto 50px;
	border: 1px solid var(--default-border-color);
	border-radius: var(--default-border-radius);
	padding: 10px 0;
	margin-bottom: 1rem;
}

.web-form-item-wrapper.fixed select,
.web-form-item-wrapper.fixed input,
.web-form-item-wrapper.fixed label {
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	pointer-events: none;
	opacity: 0.8;
}

.web-form-item-wrapper .dnd-card .dragger {
	grid-row: 1/3;
	grid-column: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.web-form-item-wrapper .dnd-card .input-field {
	grid-row: 1;
	grid-column: 2/4;
	padding: 0 20px;
	margin-bottom: 10px;
}

.web-form-item-wrapper .dnd-card .input-required {
	grid-row: 2;
	grid-column: 2/3;
	padding: 0 20px;
}

.web-form-item-wrapper .dnd-card .delete-webform-field {
	grid-row: 2;
	grid-column: 3;
	padding: 0 10px;
}

.web-form-item-wrapper .dnd-card .delete-webform-field button i {
	color: var(--default-font-color);
}
