.app-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
}

@media screen and (max-width: 767px) {
	.app-container {
		flex-direction: column;
	}
}
