.app-login {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}

.app-login .login-logo {
	text-align: center;
}

.app-login .login-logo img {
	height: 100px;
}

.app-login .login-title {
	font-family: var(--default-font-bold);
	font-size: 32px;
	margin: 20px 0 30px 0;
	text-align: center;
}

.app-login .login-form {
	max-width: 550px;
	width: 100%;
	padding: 25px;
}

.app-login .login-form .btn-go-back {
	/* font-size: 14px; */
}

.app-login .login-footer {
	width: 300px;
	/* margin-top: auto; */
	padding: 50px 0;
	text-align: center;
}

.app-login .login-footer .privacy {
	margin-top: 50px;
	font-size: 14px;
}

.app-login .login-footer .copyright {
	margin-top: 20px;
	font-size: 14px;
}

@media screen and (max-width: 767px) {
	.app-login .login-logo img {
		height: 60px;
	}

	.app-login .login-title {
		font-size: 24px;
	}

	.app-login .login-footer {
		padding: 30px 0;
	}
}
