.dropzone-uploader {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px;
	/* height: 150px; */
	border-width: 2px;
	border-radius: 10px;
	border-color: #ddd;
	border-style: dashed;
	background-color: #f3f0f0;
	color: #a9a9a9;
	outline: none;
	transition: border .24s ease-in-out;
	cursor: pointer;
}

.dropzone-uploader div[role] {
	padding: 30px 0px;
}

.dropzone-uploader aside {
	text-align: center;
	margin-bottom: 10px;
}

.dropzone-uploader .uploaded-file i {
	color: #12924c;
	font-size: 20px;
}

.dropzone-uploader .download-example {
	text-align: center;
	margin-bottom: 10px;
}

.dropzone-uploader .download-example .btn {
	font-size: 14px;
}
