.app-content-container {
	flex: 1;
	height: 100%;
	background-color: #fbfbfb;
}

@media screen and (max-width: 767px) {
	.app-content-container {
		width: 100%;
		height: calc(100% - 75px);
	}
}
