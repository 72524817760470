.app-customcontent-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	gap: var(--default-margin);
}

.app-customcontent-container .customcontent-sidebar-container {
	background-color: #ededed;
	border-radius: 15px;
	min-width: 25%;
	height: calc(100% - 65px);
}

.app-customcontent-container .customcontent-content-container {
	flex: 1;
}

.app-customcontent-container .customcontent-content-container .app-scrollable-container-content {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.app-customcontent-container .customcontent-content-container .app-scrollable-container-content .footer-container {
	flex-basis: 100%;
	padding-bottom: 0;
}
