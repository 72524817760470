.app-scrollable-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
}

.app-scrollable-container > * {
	width: 100%;
}

@media screen and (max-width: 767px) {
	.app-scrollable-container {
		width: 100%;
	}
}
