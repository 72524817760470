.customcontent-content-area {
	display: flex;
	flex-direction: column;
	flex: 1 1;
	overflow-y: auto;
}

.customcontent-content-area .customcontent-form-container {
	flex: 1;
}

.app-customcontent-container .customcontent-content-container .app-scrollable-container-content {
	flex-wrap: nowrap;
}
