.media-list-container {
	padding: 5px 10px;
	border: 1px solid var(--default-border-color);
	border-radius: var(--default-border-radius);
}

.media-list-container .app-scrollable-container-content {
	margin-top: 0;
	padding: 0;
}
