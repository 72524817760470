.app-tools-container {
	padding: 0;
}

.app-tools-container .tools-content-container .app-scrollable-container-header {
	padding: 30px 40px 10px 40px;
}

.app-tools-container .tools-content-container .app-scrollable-container-content {
	display: flex;
	flex-direction: column;
	padding: 20px 40px 0 40px;
}

.tools-content-container .app-scrollable-container-content {
	display: flex;
	flex-direction: column;
}

.app-tools-container .tools-group {
	/* display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: var(--default-margin);
	padding: 5px 0; */
	padding: 20px 10px;
}

.app-tools-container .tools-group .tools-group-item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* justify-content: center; */
	padding: 15px;
	padding-top: 50px;
	/* width: 400px; */
	height: 270px;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

.app-tools-container .tools-group .tools-group-item-link {
	text-decoration: none;
	color: var(--default-color);
	padding-right: 50px;
	padding-bottom: 25px;
}

/* .app-tools-container .tools-group .tools-group-item-link:last-child {
	padding-right: 0;
} */

.app-tools-container .tools-group .tools-group-item-link:hover .tools-group-item {
	text-decoration: none;
	background-color: #f0f0f0;
}

.app-tools-container .tools-group .tools-group-item .tools-group-item-title {
	font-size: 24px;
	font-weight: bold;
	margin-top: 10px;
}

.app-tools-container .tools-group .tools-group-item .tools-group-item-icon img {
	width: 70px;
	height: 70px;
	margin-bottom: 10px;
}

.app-tools-container .tools-group .tools-group-item .tools-group-item-icon i {
	font-size: 70px;
	margin-bottom: 10px;
}

.app-tools-container .tools-group .tools-group-item .tools-group-item-subtitle {
	font-size: 18px;
}

.app-tools-container .tools-group .tools-group-item .tools-group-item-note {
	position: absolute;
	top: 70%;
	font-size: 14px;
	padding: 20px;
	text-align: center;
}

.app-tools-container .tools-group .tools-group-item .tools-group-item-note .btn {
	font-size: 14px;
}

@media screen and (max-width: 1023px) {
	.app-tools-container .tools-content-container .app-scrollable-container-header {
		padding: 20px;
	}

	.app-tools-container .tools-content-container .app-scrollable-container-content {
		padding: 0 10px;
	}

	/* .app-tools-container .tools-group {
		flex-direction: column;
	} */

	.app-tools-container .tools-group .tools-group-item {
		/* width: 100%; */
		/* padding: 20px; */
		height: 200px;
	}

	.app-tools-container .tools-group .tools-group-item {
		padding-top: 20px;
	}

	.app-tools-container .tools-group .tools-group-item-link {
		padding-right: 10px;
	}

	.app-tools-container .tools-group .tools-group-item .tools-group-item-icon img {
		width: 30px;
		height: 30px;
	}

	.app-tools-container .tools-group .tools-group-item .tools-group-item-icon i {
		font-size: 30px;
	}

	.app-tools-container .tools-group .tools-group-item .tools-group-item-title {
		font-size: 16px;
		margin-top: 0;
	}

	.app-tools-container .tools-group .tools-group-item .tools-group-item-note {
		top: 45%;
		padding: 10px;
	}
}
