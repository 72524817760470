.keywords-sidebar-header-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 20px;
	border-bottom: 1px solid var(--default-border-color);
	color: var(--default-font-color);
}

.keywords-sidebar-header-number {
	margin-left: 20px;
}

.keywords-sidebar-header-add-button {
	margin-left: 5px;
}

.keywords-sidebar-header-search {
	position: relative;
}
