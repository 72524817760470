.contacts-list-container {
	padding: 20px 0;
	flex: 1 1;
}

.contacts-list-container table thead {
	position: sticky;
	top: 0;
}

.table th.sortable {
	cursor: pointer;
}

.table th.up::before {
	content: "\f0de";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	visibility: visible;
	font-size: 16px;
	margin-right: 5px;
	position: relative;
	top: 5px;
}

.table th.down::before {
	content: "\f0dd";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	visibility: visible;
	font-size: 16px;
	margin-right: 5px;
	position: relative;
	top: -2px;
}

.table th.default::before {
	content: "\f0dc";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	visibility: visible;
	font-size: 16px;
	margin-right: 5px;
}

th.up,
th.default,
th.down {
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center right;
}

.contacts-list-container table tr.active {
	background-color: var(--list-selected-row);
}

.contacts-list-container table td.settings-action {
	text-align: right;
}

.contacts-list-container table td .btn.btn-link {
	color: #555555;
}
