.account-settings-container {
	/* width: 50%; */
	padding: 20px 0px;
}

.account-settings-container .form-section {
	padding: 30px 20px;
}

.account-settings-container .form-section-header {
	font-size: 18px;
	text-transform: uppercase;
	padding-bottom: 15px;
	margin: 0;
	border-bottom: 1px solid var(--default-border-color);
}

.account-settings-container .form-section-title {
	font-weight: bold;
	padding: 0;
}

.account-settings-container .form-section-action {
	text-align: right;
}

.account-settings-container .form-section-action .btn {
	text-transform: uppercase;
}

.account-settings-container .form-section-action i {
	margin-right: 5px;
}

.account-settings-container .form-section-fields {
	padding: 20px 30px 0px 30px;
}

.account-settings-container .form-section-field .form-group {
	width: 80%;
}

.account-settings-container .form-section-field .form-group label {
	text-transform: uppercase;
	margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
	.account-settings-container {
		width: 100%;
	}

	.account-settings-container .form-section {
		padding: 0;
		margin-bottom: 30px;
	}

	.account-settings-container .form-section-fields {
		padding: 0;
	}

	.account-settings-container .form-section-field .form-group {
		margin: 10px 0;
		width: 100%;
	}
}
