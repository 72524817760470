.broadcast-message-link a {
	color: var(--default-font-color);
	text-decoration: none;
}

.broadcast-message-date {
	position: relative;
	margin-left: 20px;
	top: 2px;
}
