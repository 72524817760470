.message-thread-container {
	padding: 7px;
	margin-bottom: 2px;
	display: grid;
	font-size: 14px;
	cursor: pointer;
}

.message-thread-container.active {
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.message-thread-avatar {
	grid-row: 1/3;
	grid-column: 1;
	align-items: center;
	display: flex;
	margin-right: 5px;
}

.message-thread-avatar img {
	width: 35px;
	height: 35px;
	border: 0;
	border-radius: 50%;
	background-color: #e1e1e1;
}

.message-thread-contact-name {
	grid-row: 1;
	grid-column: 2/7;
	font-weight: bold;
	padding: 0px 5px;
}

.message-thread-contact-status {
	position: relative;
	bottom: 1px;
	font-size: 10px;
	margin-left: 10px;
}

.message-thread-contact-status.active {
	color: var(--contact-active-color);
}

.message-thread-contact-status.invalid {
	color: var(--contact-invalid-color);
}

.message-thread-contact-status.unsubscribed {
	color: var(--contact-unsubscribed-color);
}

.message-thread-body {
	grid-row: 2;
	grid-column: 2/7;
	padding: 0 5px;
	margin-top: 0px;
	width: 245px;
}

.message-thread-body span {
	width: 100%;
}

.message-thread-time {
	grid-row: 1;
	grid-column: 8/8;
	text-align: center;
	width: 65px;
}

.message-thread-count {
	grid-row: 2;
	grid-column: 8/8;
	text-align: center;
	margin-top: 5px;
	display: flex;
	justify-content: center;
}

.message-thread-count span {
	width: 25px;
	height: 22px;
	background: #52cdb1;
	color: #fff;
	border-radius: 5px;
}
