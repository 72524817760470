.broadcast.broadcast-placeholder {
	padding: calc(var(--default-margin) / 2);
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	gap: var(--default-margin);
}

.broadcast.broadcast-placeholder .content {
	flex: 1;
}
